var Resources = {};

window.Resources = Resources;

Resources.IndexDataTable = (function() {
  return {
    init: function(jqId) {
      var default_options = window.datatableDefaultOptions;
      var options = {
        "ajax": {
          url: $(jqId).data('url'),
          method: 'GET',
          data: {}
        },
      };
      var options = $.extend(default_options, options);
      $(jqId).DataTable(options);

    },
  };
})();
