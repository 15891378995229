
import React from 'react';

const Button = ({primary = false, label, style, children, disabled = false, ...props}) => (
  <button
    disabled={disabled}
    className={`_button ${primary ? 'primary_background' : ''} ${disabled ? 'disabled_button' : ''}`}
    style={style}
    {...props}
  >
    {label || children}
  </button>
);

export default Button;
