import React, {useEffect, useState} from 'react';
import AddTypeResult from './type_result/AddTypeResult';
import RemoveTypeResult from './type_result/RemoveTypeResult';

const TypeResult = (
  {
    quiz_id,
    organization_id,
    index,
    record = [],
    type_results = [],
    questions = [],
  }) => {

    const [currentTypeResults, setCurrentTypesResults] = useState([]);

    useEffect(() => {
      if(record && record.type_results) {
        setCurrentTypesResults(record.type_results);
      }
    }, []);

    const handleAdd = (newTypeResult) => {
      if(newTypeResult) {
        setCurrentTypesResults(prev => ([...prev, newTypeResult]));
      }
    }

    const handleRemove = (typeResultId) => {
      if(typeResultId) {
        setCurrentTypesResults(prev => prev.filter((c) => c.id !== typeResultId));
      }
    };

  return (
    <ul id={index} className="no_padding_left">
      <AddTypeResult
        organization_id={organization_id}
        quiz_id={quiz_id}
        type_results={type_results}
        answers={record}
        questions={questions}
        index={index}
        currentTypeResults={currentTypeResults}
        onAdd={handleAdd}
      />
      {currentTypeResults.map((type_result, index_type_result) => (
            <RemoveTypeResult
              key={type_result.id}
              quiz_id={quiz_id}
              type_result={type_result}
              answers={record}
              questions={questions}
              index={index}
              index_type_result={index_type_result}
              onDelete={handleRemove}
            />
        )
      )}
    </ul>
  );
}

export default TypeResult;
