window.datatableDefaultOptions = {
    processing: true,
    serverSide: true,
    language: {
      sEmptyTable: "Nothing to show",
      search: '<i class="fa fa-filter text-primary" aria-hidden="true"></i>',
      searchPlaceholder: 'Search'
    },
    responsive: true,
    iDisplayLength: 25,
    searchDelay: 550
  };

require("datatables.net-bs4")
require("./datatables/resources")
require("./datatables/users")
require("./datatables/products")
require("./datatables/quizzes")
require("./datatables/answers")
require("./datatables/questions")
require("./datatables/results")
require("./datatables/templates")
require("./datatables/productTypeResult")
require("./datatables/possibleResults")
require("./datatables/typeResults")

$(document).ready(function() {
  if ($('.simple_dataTable').length){
    $('.simple_dataTable').DataTable({
      language: {
        sEmptyTable: "Nothing to show",
        search: '<i class="fa fa-filter text-primary" aria-hidden="true"></i>',
        searchPlaceholder: 'Search'
      },
      responsive: true,
      iDisplayLength: 50,
    });
  }
});