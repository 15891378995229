import React, {useState, useEffect} from "react";
import {
  BsFillCaretDownFill,
  BsCaretUpFill,
} from "react-icons/bs";
import useOutsideClick from '../hook/useOutsideClick';

const Select = ({onChange, options = []}) => {
  const [showOptions, setShowOptios] = useState();
  const [selectedOption, setSelectedOption] = useState();
  const [heightSelect, setHeightSelect] = useState(0);

  const hanldeShow = () => {
    setShowOptios(prev => !prev);
  }

  const handleChange = (option) => {
    setSelectedOption(option);
    onChange(option);
  }

  const handleClickOutside = () => {
   setShowOptios(false);
  };

  const selectRef = useOutsideClick(handleClickOutside);

  useEffect(() => {
    if(selectedOption && selectedOption?.id && !options.find(option => option.id === selectedOption.id)) {
      handleChange(null);
    }
  }, [options])

  useEffect(() => {
    setHeightSelect(selectRef.current.getBoundingClientRect().height);
  }, [selectedOption, selectRef, showOptions]);

  return (
    <div
      ref={selectRef}
      className="_row select_container"
      onClick={hanldeShow}
    >
      {selectedOption?.title || "Select"}
      {showOptions ? <BsCaretUpFill size={15} /> : <BsFillCaretDownFill size={15} />}
      {showOptions ? (
        <div
          className={`select_options ${options.length === 0 ? "disabled_button" : "primary_background"}`}
          style={{
           top: heightSelect,
          }}
        >
          {options.length > 0 ?
            options.map(option => (
              <span
                key={option?.id}
                onClick={() => handleChange(option)}>{option?.title}</span>
            ))
          : (
            <span className="text-center">
              No options found
            </span>
          )}
        </div>
      ) : null}
    </div>
  );
}

export default Select;
