$(document).ready(function() {
  $('#questions-index-datatable').DataTable( {
    "processing": true,
    "serverSide": true,
    "language": {
      sEmptyTable: "Nothing to show",
      search: '<i class="fa fa-filter text-primary" aria-hidden="true"></i>',
      searchPlaceholder: 'Search'
    },
    ajax: {
      url: $('#questions-index-datatable').data('url'),
      data: function(d) {
        d.quiz_id = $('#filter_quiz').val()
      }
    },
    responsive: true,
    aoColumnDefs: [
      { aTargets: [6], width: '20px', sortable: false }
    ],
    iDisplayLength: 25,
    searchDelay: 550
  });
  $('#filter_quiz').on('change', () => $('#questions-index-datatable').DataTable().ajax.reload());
});
