$(document).ready(function() {
  $('#results-index-datatable').DataTable( {
    "processing": true,
    "serverSide": true,
    "language": {
      sEmptyTable: "Nothing to show",
      search: '<i class="fa fa-filter text-primary" aria-hidden="true"></i>',
      searchPlaceholder: 'Search'
    },
    ajax: {
      url: $('#results-index-datatable').data('url'),
      data: function(d) {
        d.quiz_id = $('#filter_quiz').val(),
        d.start_at = $('#filter_start_at').val(),
        d.end_at = $('#filter_end_at').val()
      }
    },
    responsive: true,
    iDisplayLength: 25,
    searchDelay: 550
  });
  $('#filter_quiz').on('change', () => $('#results-index-datatable').DataTable().ajax.reload());
  $('#filter_start_at').on('change', () => $('#results-index-datatable').DataTable().ajax.reload());
  $('#filter_end_at').on('change', () => $('#results-index-datatable').DataTable().ajax.reload());
});
