$(document).ready(function() {
  $('#product-type-result-index-datatable').DataTable( {
    "processing": true,
    "serverSide": true,
    "language": {
      sEmptyTable: "Nothing to show",
      search: '<i class="fa fa-filter text-primary" aria-hidden="true"></i>',
      searchPlaceholder: 'Search'
    },
    ajax: {
      url: $('#product-type-result-index-datatable').data('url'),
    },
    responsive: true,
    iDisplayLength: 25,
    searchDelay: 550
  });
});
