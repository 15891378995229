export const removeOptions = (selectElement) => {
  let n  = selectElement.options.length - 1;
  let i = n, length = n;
  for (i = length; i >= 0; i--) selectElement.remove(i);
}

export const addOption = (select, item) => {
  let option = document.createElement('option');
  let ids = []
  try{
    ids = JSON.parse(select.dataset.selected)
  }
  catch(error){
  }
  option.text = item.name;
  option.value = item.key;
  option.selected = ids.includes(item.key.toString());
  select.appendChild(option);
}
