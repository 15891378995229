if (window.Modal == undefined)
  window.Modal = {};

window.Modal.Resources = (function() {
  return {
    init: function(button, callback) {
      return $(button).on('click', function(event) {
        event.preventDefault();
        $.get('/dashboard/resources/file_manager', function(data) {
          $("#tmp_modal").remove();
          $("body").append("<div id='tmp_modal'></div>");
          $("#tmp_modal").append(data);
          $("#tmp_modal .img-thumbnail").on(
            'click', function(event) {
              callback(event);
              $($("#tmp_modal .modal").first()).modal('hide');
            });
          return $($("#tmp_modal .modal").first()).modal("show");
        });

      });
    },
    run: function(callback) {
      $.get('/dashboard/resources/file_manager', function(data) {
        $("#tmp_modal").remove();
        $("body").append("<div id='tmp_modal'></div>");
        $("#tmp_modal").append(data);
        $("#tmp_modal .img-thumbnail").on(
          'click', function(event) {
            callback(event);
            $($("#tmp_modal .modal").first()).modal('hide');
          });
        return $($("#tmp_modal .modal").first()).modal("show");
      });
    },
  };
})();
