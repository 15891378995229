import { removeOptions, addOption } from './utils/select';

export const addOptionsByAjax = (selectQuestion, selectAnwers) => {
  $.ajax({ url: `/dashboard/possible_results/question/${selectQuestion.value}/answers` })
  .done((data) => {
    data.forEach(answer => addOption(selectAnwers, answer));
  })
}

document.addEventListener("DOMContentLoaded", () => {
  let selectQuestion = document.getElementById('questionSelectPossibleResult');
  let selectAnwers = document.getElementById('answerSelectPossibleResult');

  if (selectQuestion && selectAnwers) {
    addOptionsByAjax(selectQuestion, selectAnwers);
    selectQuestion.addEventListener('change', () => {
      removeOptions(selectAnwers);
      addOptionsByAjax(selectQuestion, selectAnwers);
    });
  }
});
