$(document).ready(function() {
  $('#quizzes-index-datatable').DataTable( {
    "processing": true,
    "serverSide": true,
    "language": {
      sEmptyTable: "Nothing to show",
      search: '<i class="fa fa-filter text-primary" aria-hidden="true"></i>',
      searchPlaceholder: 'Search'
    },
    ajax: {
      url: $('#quizzes-index-datatable').data('url'),
    },
    responsive: true,
    // aoColumnDefs: [
    //   { aTargets: [4], width: '20px', sortable: false }
    // ],
    iDisplayLength: 25,
    searchDelay: 550
  });
});
