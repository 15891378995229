import React, {useState, useEffect} from "react";
import {
  BsSquare,
  BsCheckSquare,
} from "react-icons/bs";
import Select from "./Select";

const Checkbox = ({checked, handleCheckbox}) => (
  <td className="center_icon" onClick={handleCheckbox}>
    {checked ? <BsCheckSquare size={20} /> : <BsSquare size={20} />}
 </td>
);

const Row = ({row, checkRows, setSelected, columns, selectOptions, setSelectedOption}) => {
  const [checked, setChecked] = useState(false);

  const handleCheckbox = () => setChecked(prev => !prev);

  useEffect(() => {
    if (checkRows) {
      if (checked) {
        setSelected(prev => [...prev, row]);
      } else {
        setSelected(prev => {
          const newSelected = prev.filter(e => {
            let isRow = true;
            columns.forEach((element, index) => {
              isRow = isRow &&  e[index].id === row[index].id;
            });
            return !isRow;
          });
          return newSelected;
        });
      }
    }
  }, [checked, checkRows]);

  return (
    <tr className={`${checked ? 'secondary_background' : ''}`} >
      {checkRows && <Checkbox checked={checked} handleCheckbox={handleCheckbox} />}
      {Array.isArray(row) ?
        row.map(r => (
          <td key={r?.id}>{r?.title}</td>
        ))
      : null}
      {selectOptions ? (
        <td>
          <Select onChange={setSelectedOption} options={selectOptions} />
        </td>
      ) : null}
    </tr>
  );
}

const Table = ({columns, rows, checkRows = false, setSelected, selectOptions, onSelect}) => {
  return (
    <table id="combinations-index-datatable" className="table table-bordered aa_simple_dataTable" width="100%" cellSpacing="0">
      <thead>
        <tr>
          {checkRows ? <th>Select</th> : null}
          {columns.map((c, index) =>
            <th key={index}>{c && c.title ? c.title : ''}</th>
          )}
        </tr>
      </thead>
      <tbody>
      {Array.isArray(rows) ?
        rows.map((row, index) => (
          <Row
            key={index}
            row={row}
            checkRows={checkRows}
            setSelected={checkRows ? setSelected : null}
            columns={columns}
            selectOptions={selectOptions}
            setSelectedOption={onSelect}
          />
        )) : null}
      </tbody>
    </table>
  );
};

export default Table;
