const Swal = require('sweetalert2/dist/sweetalert2.all.min')

const HASH_CODIFIED = "%23"; // #
const ID_KEY = `${HASH_CODIFIED}id${HASH_CODIFIED}`;

$(document).on('turbolinks:load', () => {
  $('.sweet-alert-destroy').click(function(){
    Swal.fire({
      title: 'Destroy',
      text: 'Confirm destroy element?',
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Confirm',
    }).then((result) => {
      if (result.isConfirmed) {
        $.ajax({
          url: ajaxUrl(),
          headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
          type: 'DELETE',
          success: () => {},
          error: (xhr) => Swal.fire({icon: 'error', title: 'Something went wrong!', text: xhr.statusText})
        });
      }
    });
  });

  const ajaxUrl = () => {
    let url = $('.sweet-alert-destroy').data('url');
    if (url?.includes(ID_KEY)) {
      url = url.replace(ID_KEY, $('#filter_quiz').val());
    }
    return url;
  }
});
