document.onreadystatechange = () => {
  if (document.readyState === 'complete') {
    function readURL(input) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function(e) {
          $('#blah').attr('src', e.target.result);
        }
        reader.readAsDataURL(input.files[0]);
      }
    }
    $("#imgInp").change(function() {
      readURL(this);
    });
  }
}
//Show image on hover link
//example
//<a class="btn btn-primary" data-toggle="popover-hover" data-img="https://mdbootstrap.com/img/logo/mdb192x192.jpg">Hover over me</a>
// popovers initialization - on hover
$(document).ready(function() {
  $('[data-toggle="popover-hover"]').popover({
    html: true,
    trigger: 'hover',
    placement: 'bottom',
    content: function () {
      var url_image = $(this).data('img');
      if (url_image == "self_input")
        url_image = $(this).val();
      if(url_image)
        return '<img class="img-thumbnail" src="' + url_image + '" />';
      else
        return '<p>No image</p>';

    }
  });
});