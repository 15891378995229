import React, {useRef, useEffect} from "react";
import {
  BsXSquareFill,
} from "react-icons/bs";
import Button from "./Button";

const Modal = ({showModal, setShowModal, modalContent, labelButton, closeButton = false, onClick, disabledButton}) => {
  const modalRef = useRef();

  const handleHideModal = () => {
    const container = document.getElementById('page-top');
    container.style.overflow = 'scroll';
    const bgModal = document.getElementById('bg_modal');
    bgModal.style.display = 'none';
    if (showModal) setShowModal(false);
  }

  const handleShowModal = () => {
    const container = document.getElementById('page-top');
    container.style.overflow = 'hidden';
    const bgModal = document.getElementById('bg_modal');
    bgModal.style.display = 'flex';
    if (!showModal) setShowModal(true);
  }

  useEffect(() => {
    if (showModal && modalRef) {
      handleShowModal();
      modalRef.current.scrollIntoView({behavior: 'smooth', block: 'center'});
    } else if (!showModal) {
      handleHideModal();
    }
  }, [showModal]);

  const handleClick = () => {
    if(onClick) {
      onClick();
    }
    handleHideModal();
  }

  return (
    <div
      ref={modalRef}
      id="modal"
      className={`modal_container ${showModal ? 'show_modal' : ''}`}
    >
      <div className="clickeable" onClick={handleHideModal}>
        <BsXSquareFill size={16} color="red" />
      </div>
      {modalContent}
      <div className={`_row buttons_container ${closeButton ? 'space_button' : 'center_button'}`} >
        <Button primary disabled={disabledButton} label={labelButton} onClick={handleClick}/>
       {closeButton ? <Button onClick={handleHideModal} label="Close" /> : null}
      </div>
    </div>
  );
}

export default Modal;
