import React, {useState} from 'react';
import IconButton from './IconButton';
import Modal from '../Modal';

const TypeResultModal = ({type, modalTitle, handleSuccess, url, method, body, successfullyMessage = '', ...props}) => {
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [success, setSuccess] = useState();

  const handleMessageModal = () => {
    if (success) {
      handleSuccess();
    }
  }

  const handleModal = () => {
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    fetch(url, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf
      },
      body: body,
    })
      .then((data) => {
        if(data?.ok) {
          setShowModal(false);
          setSuccess(true);
          setShowMessageModal(true);
        } else {
          setSuccess(false);
          setShowMessageModal(true);
          setShowModal(false);
        }
      })
      .catch((err) => console.log("Error: " + err));
  }

  return (
    <div className='end_position'>
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        onClick={handleModal}
        closeButton
        labelButton={type === "add" ? "Add" : "Delete"}
        {...props}
      />
      <Modal
        showModal={showMessageModal}
        setShowModal={setShowMessageModal}
        modalContent={
          <div className="text-center">
            <h4 style={{width: '95%'}}>
              {success ? successfullyMessage : 'Something went wrong'}
            </h4>
          </div>
        }
        labelButton="Ok"
        onClick={handleMessageModal}
      />
      <IconButton type={type} onClick={() => {
        setShowModal(true);
      }
      } />
    </div>
  );
}

export default TypeResultModal;
