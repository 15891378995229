var Users
Users = {};

window.Users = Users;

Users.IndexDataTable = (function() {
  return {
    init: function(jqId) {
      var paramsAjax = {
        url: $(jqId).data('url'),
        method: 'GET'
      }
      let quizId = document.getElementById('quiz_id');
      if (quizId) paramsAjax.data = { filters: { quiz_id: quizId.value } };
      var table;
      table = $(jqId).DataTable({
        processing: true,
        serverSide: true,
        ajax: paramsAjax,
        responsive: true,
        iDisplayLength: 25,
        sPaginationType: 'full_numbers',
        dom: 'T<"clear">lfrtpi',
        aoColumnDefs: [
          { aTargets: [7], sortable: false }
        ]
      });
      return this.filter(jqId);
    },
    filter: function(jqId) {
      return $("#filter_datatable .filter").on('change', function() {
        return $(jqId).DataTable().ajax.reload();
      });
    }
  };
})();
