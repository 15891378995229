
import React from 'react';
import {
  BsTrashFill,
  BsPlusCircle,
} from "react-icons/bs";

const IconButton = ({type, style, onClick}) => (
  <div className={`icon_button ${type === 'add' ? 'negative_margin' : ''}`} style={style} onClick={onClick}>
    {
      type === "add" ?
        <BsPlusCircle size={20}/>
      : type === 'remove' ?
        <BsTrashFill size={16} />
      : null
    }
  </div>
);

export default IconButton;
