const HASH_CODIFIED = "%23"; // #
const ID_KEY = `${HASH_CODIFIED}id${HASH_CODIFIED}`;

document.addEventListener("DOMContentLoaded", () => {
  let form = document.getElementById("form-import-possible-results");
  if (form) {
    let quizSelector = document.getElementById('import_filter_quiz');
    quizSelector.addEventListener('change', () => {
      form.action = form.action.replace(ID_KEY, quizSelector.value);
    });
  }
});