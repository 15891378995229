import React, {useState} from 'react';
import Table from '../Table';
import TypeResultModal from './TypeResultModal';

const AddTypeResult = ({currentTypeResults = [], onAdd, ...props}) => {
  const [selectedTypeResult, setSelectedTypeResult] = useState();

  const handleSuccess = () => {
    // add type result to table
    onAdd(selectedTypeResult);
  }

  return (
    <TypeResultModal
      type="add"
      modalContent={
        <>
          <div className="text-center">
            <h2>
            Add Possible Result
            </h2>
          </div>
          <Table
            columns={[...props?.questions, {title: 'Type Result'}]}
            rows={[props?.answers?._answers]}
            selectOptions={props?.type_results?.filter(t => currentTypeResults.length > 0 ? !(currentTypeResults?.find(e => e.id === t.id)) : true)}
            onSelect={setSelectedTypeResult}
          />
        </>
      }
      url="possible_results/add_type_result"
      method="post"
      body={JSON.stringify({
        quiz_id: props?.quiz_id,
        type_result_id: selectedTypeResult?.id,
        organization_id: props?.organization_id,
        array: props?.answers?._answers?.map(element => ({question_id: element?.question_id, answer_id: element?.id}))
      })}
      disabledButton={!selectedTypeResult}
      handleSuccess={handleSuccess}
      successfullyMessage={`Type result ${selectedTypeResult?.title} successfully added`}
      {...props}
    />
  );
}

export default AddTypeResult;
