import { removeOptions, addOption } from './utils/select';
import { addOptionsByAjax as addAnswerOptionsByAjax } from './answerOptions';

const addOptionsByAjax = (selectQuiz, selectQuestion, selectAnwers) => {
  $.ajax({ url: `/dashboard/possible_results/quiz/${selectQuiz.value}/questions` })
  .done((data) => {
    data.forEach(question => addOption(selectQuestion, question));
    selectQuestion.value = data.length =! 0 && data[0].key;
    removeOptions(selectAnwers);
    addAnswerOptionsByAjax(selectQuestion, selectAnwers);
  })
}

document.addEventListener("DOMContentLoaded", () => {
  let selectQuizzes = document.getElementById('quizSelectPossibleResult');
  let selectQuestions = document.getElementById('questionSelectPossibleResult');
  let selectAnwers = document.getElementById('answerSelectPossibleResult');

  if (selectQuizzes && selectQuestions && selectAnwers) {
    selectQuizzes.addEventListener('change', () => {
      removeOptions(selectQuestions);
      addOptionsByAjax(selectQuizzes, selectQuestions, selectAnwers);
    });
  }
});
