import React, {useState} from 'react';
import Table from '../Table';
import TypeResultModal from './TypeResultModal';

const RemoveTypeResult = ({onDelete, ...props}) => {
  const [selected, setSelected] = useState([]);

  const handleSuccess = () => {
    onDelete(props?.type_result?.id);
  }

  return (
    <div key={props?.type_result?.id} id={`${props?.index}-${props?.index_type_result}`} className="_row">
      <TypeResultModal
        type="remove"
        modalContent={
          <>
            <div className="text-center">
              <h2>
                Remove Possible Result for
              </h2>
              <h4 className="primary_color"> {props?.type_result?.title}</h4>
            </div>
            <Table
              checkRows
              columns={[1,2]}
              rows={props?.questions?.map(
                question => ([question, props?.answers._answers?.find(answer=> answer?.question_id === question?.id)]))}
              setSelected={setSelected}
              questions={props?.questions}
              answers={props?.answers?._answers}
            />
          </>
        }
        url="possible_results/delete_by"
        method="delete"
        body={JSON.stringify({
          quiz_id: props?.quiz_id,
          type_result_id: props?.type_result?.id,
          array_to_delete: selected.map(s => ({question_id: s[0]?.id, answer_id: s[1]?.id})),
        })}
        handleSuccess={handleSuccess}
        disabledButton={!selected?.length}
        successfullyMessage="Possible result successfully removed"
        {...props}
      />
      <p className="primary_color cursor_pointer" onClick={() => setShowModal(true)}>
        {props?.type_result?.title}
      </p>
    </div>
  );
}

export default RemoveTypeResult;
